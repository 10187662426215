import React, { useEffect, useMemo, useState } from 'react'
import { Select } from 'antd'
import { observer } from 'mobx-react'
import { sharedDataStore, ViewMode, ViewModes } from 'src/store/DataStore'
import { camelToSentenceCase } from 'src/utils/format'
import { FilterSelect } from 'src/components/Feed//FeedPostList'

export interface ViewSwitcherProps {
  excludesViewModes?: ViewMode[]
  initialViewMode?: ViewMode
  handleViewModeChange?: (mode: ViewMode) => void
  updateLocalSettingsViewMode?: boolean
}

export const ViewSwitcher = observer(
  ({
    excludesViewModes = [],
    initialViewMode,
    handleViewModeChange,
    updateLocalSettingsViewMode = true,
  }: ViewSwitcherProps) => {
    const [viewMode, setViewMode] = useState<ViewMode>()

    useEffect(() => {
      if (initialViewMode) {
        setViewMode(initialViewMode)
      } else {
        setViewMode(sharedDataStore.localSettings.viewMode)
      }
    }, [initialViewMode])

    useEffect(() => {
      if (excludesViewModes && excludesViewModes.includes(viewMode)) {
        const defaultViewMode = ViewModes[0]
        setViewMode(defaultViewMode)
        handleViewModeChange?.(defaultViewMode)
        if (updateLocalSettingsViewMode) {
          sharedDataStore.localSettings = { ...sharedDataStore.localSettings, viewMode: defaultViewMode }
        }
      }
    }, [excludesViewModes, viewMode, handleViewModeChange, updateLocalSettingsViewMode])

    const onViewModeChange = (mode: ViewMode) => {
      setViewMode(mode)
      handleViewModeChange?.(mode)
      if (updateLocalSettingsViewMode) {
        sharedDataStore.localSettings = { ...sharedDataStore.localSettings, viewMode: mode }
      }
    }

    const viewModeOptions = useMemo(
      () => ViewModes.filter((mode) => !excludesViewModes.includes(mode)),
      [excludesViewModes]
    )

    return (
      <FilterSelect value={viewMode} onChange={onViewModeChange} style={{ width: 'max-content' }}>
        {viewModeOptions.map((mode) => (
          <Select.Option key={mode} value={mode}>
            {camelToSentenceCase(mode)}
          </Select.Option>
        ))}
      </FilterSelect>
    )
  }
)
