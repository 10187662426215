import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { computed, observable, makeObservable, IReactionDisposer, autorun } from 'mobx'
import { ContainerDiv } from 'src/components/Common/Cards'
import { DatePicker, Select } from 'antd'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { User } from 'src/models/User'
import { FeedTag } from 'src/models/FeedTag'
import { LibraryCategory } from 'src/pages/Library'
import { sharedDataStore } from 'src/store/DataStore'
import { getQueryParam, getQueryParams } from 'src/utils/urlParams'
import moment from 'moment'
import { RouteComponentProps } from 'react-router-dom'
import { clearFalseyValues, titleCase } from 'src/utils/format'
import {
  contentTypeOptions,
  roleOptions,
  statusOptions,
  archiveStatusOptions,
  sharingPermissionOptions,
  templateFilterOptions,
  CustomMetadataFieldOptions,
} from 'src/models/Config'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { FilterDropdownButton } from 'src/components/Common/Button'
import FjPopover, { FjPopoverContent } from 'src/components/Common/FjPopover'
import { LearningContentType, getContentCategoryTitle } from 'src/utils/content'
import { ViewSwitcher, ViewSwitcherProps } from 'src/components/Feed/ViewSwitcher'
import { FilterSelect } from 'src/components/Feed/FeedPostList'
import { Filter as FilterIcon } from 'react-feather'
import { Colors } from 'src/constants/colors'
import { HubFilter } from 'src/components/Common/Filter/AutoCompleteFilter'
import { debounce } from 'src/utils/debounce'

const { RangePicker } = DatePicker

export type SortBy = 'recent' | 'popular' | 'alphabetical'

export interface AutoCompleteOption {
  key: string
  value: string
  id: string
  label: string
}

const AUTOCOMPLETE_KEYS = ['tag', 'opportunity', 'author', 'manager', 'hub'] as const
const DATE_KEYS = ['fromDate', 'toDate', 'expiryFromDate', 'expiryToDate', 'closedFromDate', 'closedToDate'] as const
const SELECT_KEYS = [
  'stagename',
  'filetype',
  'group',
  'role',
  'status',
  'archiveStatus',
  'sharingPermission',
  'contentType',
  'isTemplate',
] as const

type AutoCompleteFilterKey = (typeof AUTOCOMPLETE_KEYS)[number]
type DateFilterKey = (typeof DATE_KEYS)[number]
type SelectFilterKey = (typeof SELECT_KEYS)[number]
export type FilterOptionKey = AutoCompleteFilterKey | SelectFilterKey | DateFilterKey
export type FilterKey = AutoCompleteFilterKey | SelectFilterKey | 'date' | 'expiryDate' | 'closedDate'

export interface Filter {
  key: FilterKey
  label: string
}

type Filters = {
  [key in AutoCompleteFilterKey | SelectFilterKey]?: any[]
} & {
  [key in DateFilterKey]?: moment.Moment
}

interface FilterDropdownProps {
  contentTypeOptions: AutoCompleteOption[]
  filter: Filter
  selectedFilters: Filters
  addFilterOption: (key: FilterOptionKey, value: AutoCompleteOption | moment.Moment) => void
  removeFilterOption: (key: FilterOptionKey, filter?: AutoCompleteOption) => void
  clearFilter: (key: FilterKey) => void
  getCustomMetadataFieldOptions: (key: FilterKey) => AutoCompleteOption[]
}

@observer
export class FilterDropdown extends Component<FilterDropdownProps> {
  @observable options: AutoCompleteOption[] = []
  @observable fromDate: moment.Moment = undefined
  @observable toDate: moment.Moment = undefined
  @observable expiryFromDate: moment.Moment = undefined
  @observable expiryToDate: moment.Moment = undefined
  @observable closedFromDate: moment.Moment = undefined
  @observable closedToDate: moment.Moment = undefined
  @observable dropdownOpen = false

  constructor(props: FilterDropdownProps) {
    super(props)
    makeObservable(this)
  }

  @computed get selectedOptions() {
    const selectedOptions: AutoCompleteOption[] = this.props.selectedFilters[this.props.filter.key] ?? []
    return selectedOptions
  }

  handleDateFilterChange = (dateRange: moment.Moment[]) => {
    const { addFilterOption } = this.props
    if (dateRange[0]) addFilterOption('fromDate', dateRange[0])
    if (dateRange[1]) addFilterOption('toDate', dateRange[1])

    if (dateRange[0] && dateRange[1]) {
      this.fromDate = undefined
      this.toDate = undefined
      this.dropdownOpen = false
    }
  }

  handleExpiryDateFilterChange = (dateRange: moment.Moment[]) => {
    const { addFilterOption } = this.props
    if (dateRange[0]) addFilterOption('expiryFromDate', dateRange[0])
    if (dateRange[1]) addFilterOption('expiryToDate', dateRange[1])

    if (dateRange[0] && dateRange[1]) {
      this.expiryFromDate = undefined
      this.expiryToDate = undefined
      this.dropdownOpen = false
    }
  }

  handleClosedDateFilterChange = (dateRange: moment.Moment[]) => {
    const { addFilterOption } = this.props
    if (dateRange[0]) addFilterOption('closedFromDate', dateRange[0])
    if (dateRange[1]) addFilterOption('closedToDate', dateRange[1])

    if (dateRange[0] && dateRange[1]) {
      this.closedFromDate = undefined
      this.closedToDate = undefined
      this.dropdownOpen = false
    }
  }

  populateAutoCompleteFilterOptions = async (key: AutoCompleteFilterKey, value: string) => {
    let data
    switch (key) {
      case 'tag':
        data = await FeedTag.list({ search: value })
        this.options = data.map((item: FeedTag) => ({ ...item, value: item.tag, label: item.tag }))
        break
      case 'opportunity':
        data = await SfOpportunity.list({ search: value })
        this.options = data.data.map((item: SfOpportunity) => ({ ...item, value: item.id, label: item.name }))
        break
      case 'author':
        data = await User.list({
          search: value,
          access_role: 'standard,admin,manager,partner',
        })
        this.options = data.data.map((item: User) => ({
          ...item,
          key: item.id,
          value: item.id,
          label: item.fullName,
        }))
        break
      case 'manager':
        data = await User.list({ search: value, has_reports: true })
        this.options = data.data.map((item: User) => ({
          ...item,
          key: item.id,
          value: item.id,
          label: item.fullName,
        }))
        break
      default:
        break
    }
  }

  handleDropdownClick = () => {
    this.dropdownOpen = !this.dropdownOpen
    const { key } = this.props.filter
    if (AUTOCOMPLETE_KEYS.includes(key as AutoCompleteFilterKey)) {
      this.populateAutoCompleteFilterOptions(key as AutoCompleteFilterKey, '')
    }
  }

  filterSearch = (key: AutoCompleteFilterKey, value: string) => {
    this.populateAutoCompleteFilterOptions(key, value)
  }

  filterOptions = (key: SelectFilterKey) => {
    const options: {
      [key in SelectFilterKey]: any
    } = {
      stagename: sharedDataStore.stageNameOptions,
      filetype: sharedDataStore.assetFileTypeOptions,
      group: sharedDataStore.groupOptions,
      role: roleOptions,
      status: statusOptions,
      archiveStatus: archiveStatusOptions,
      isTemplate: templateFilterOptions,
      sharingPermission: sharingPermissionOptions,
      contentType: this.props.contentTypeOptions,
    }
    return options[key] ?? []
  }

  createFilterDropdown = () => {
    const { key } = this.props.filter
    const { addFilterOption, removeFilterOption, getCustomMetadataFieldOptions } = this.props

    return (
      <>
        {AUTOCOMPLETE_KEYS.includes(key as AutoCompleteFilterKey) ? (
          <FjPopoverContent
            options={this.options}
            values={this.selectedOptions}
            onSearch={(value: string) => this.filterSearch(key as AutoCompleteFilterKey, value)}
            addFilterOption={(option) => addFilterOption(key as AutoCompleteFilterKey, option)}
            removeFilterOption={(option) => removeFilterOption(key as AutoCompleteFilterKey, option)}
          />
        ) : SELECT_KEYS.includes(key as SelectFilterKey) ? (
          <FjPopoverContent
            options={this.filterOptions(key as SelectFilterKey)}
            values={this.selectedOptions}
            addFilterOption={(option) => addFilterOption(key as SelectFilterKey, option)}
            removeFilterOption={(option) => removeFilterOption(key as SelectFilterKey, option)}
          />
        ) : key.includes('custom_fields') ? (
          <FjPopoverContent
            options={getCustomMetadataFieldOptions(key)}
            values={this.selectedOptions}
            addFilterOption={(option) => addFilterOption(key as FilterOptionKey, option)}
            removeFilterOption={(option) => removeFilterOption(key as FilterOptionKey, option)}
          />
        ) : key.includes('date') ? (
          <RangePicker
            defaultOpen
            allowEmpty={[true, true]}
            value={[this.fromDate, this.toDate] as any}
            onChange={this.handleDateFilterChange}
          />
        ) : key.includes('expiryDate') ? (
          <RangePicker
            defaultOpen
            allowEmpty={[true, true]}
            value={[this.expiryFromDate, this.expiryToDate] as any}
            onChange={this.handleExpiryDateFilterChange}
          />
        ) : key.includes('closedDate') ? (
          <RangePicker
            defaultOpen
            allowEmpty={[true, true]}
            value={[this.closedFromDate, this.closedToDate] as any}
            onChange={this.handleClosedDateFilterChange}
          />
        ) : null}
      </>
    )
  }

  @computed get filterLabel() {
    const { filter, selectedFilters } = this.props

    let filterLabel = filter.label
    if (filter.key === 'date' && selectedFilters['fromDate'] && selectedFilters['toDate']) {
      filterLabel = `${filterLabel} | From ${selectedFilters['fromDate'].format('YYYY-MM-DD')} to ${selectedFilters[
        'toDate'
      ].format('YYYY-MM-DD')}`
    } else if (filter.key === 'expiryDate' && selectedFilters['expiryFromDate'] && selectedFilters['expiryToDate']) {
      filterLabel = `${filterLabel} | From ${selectedFilters['expiryFromDate'].format(
        'YYYY-MM-DD'
      )} to ${selectedFilters['expiryToDate'].format('YYYY-MM-DD')}`
    } else if (filter.key === 'closedDate' && selectedFilters['closedFromDate'] && selectedFilters['closedToDate']) {
      filterLabel = `${filterLabel} | From ${selectedFilters['closedFromDate'].format(
        'YYYY-MM-DD'
      )} to ${selectedFilters['closedToDate'].format('YYYY-MM-DD')}`
    } else {
      if (!this.selectedOptions.length) return filterLabel
      filterLabel =
        this.selectedOptions.length > 2
          ? `${filterLabel} | ${this.selectedOptions[0].label}, ${this.selectedOptions[1].label}, + ${
              this.selectedOptions.length - 2
            } more`
          : `${filterLabel} | ${this.selectedOptions.map((filter) => filter.label).join(', ')}`
    }
    return filterLabel
  }

  @computed get isFilterSelected() {
    const { filter, selectedFilters } = this.props

    const isFilterSelected =
      this.selectedOptions.length > 0 ||
      (filter.key === 'date' && !!selectedFilters['fromDate'] && !!selectedFilters['toDate']) ||
      (filter.key === 'expiryDate' && !!selectedFilters['expiryFromDate'] && !!selectedFilters['expiryToDate']) ||
      (filter.key === 'closedDate' && !!selectedFilters['closedFromDate'] && !!selectedFilters['closedToDate'])
    return isFilterSelected
  }

  handleClearFilter = () => {
    const { clearFilter, filter } = this.props
    clearFilter(filter.key as FilterKey)
  }

  render() {
    return (
      <FjPopover
        content={this.createFilterDropdown()}
        open={this.dropdownOpen}
        onOpenChange={(open) => (this.dropdownOpen = open)}
      >
        <FilterDropdownButton
          selected={this.isFilterSelected}
          title={this.filterLabel}
          onClick={() => this.handleDropdownClick()}
          onClear={this.isFilterSelected ? this.handleClearFilter : undefined}
        />
      </FjPopover>
    )
  }
}

type FilterCategory = LibraryCategory | 'user' | 'template'

export interface FjFilterProps {
  showFilter?: boolean
  showSort?: boolean
  showViewSwitcher?: boolean
  excludeFilters?: FilterKey[]
  initialFilters?: Filters
  excludeSearchParams?: string[]
  category: FilterCategory
  handleSetSortBy?: (sortByKey: SortBy) => void
  sortBy?: SortBy
  onFiltersChange?: (params: any) => void
  overrideContentTypeOptions?: LearningContentType[]
  overrideCustomMetadataFieldOptions?: CustomMetadataFieldOptions
  overrideFilters?: { [key in LibraryCategory]?: Filter[] }
  viewSwitcherProps?: ViewSwitcherProps
}

const notSortSupportedCategories: FilterCategory[] = ['top', 'managerPicks', 'favourites']

export const TAG_FILTER: Filter = { key: 'tag', label: 'Tags' }
export const OPPORTUNITY_FILTER: Filter = { key: 'opportunity', label: 'Opportunities' }
export const AUTHOR_FILTER: Filter = { key: 'author', label: 'Author' }
export const OWNER_FILTER: Filter = { key: 'author', label: 'Owner' }
export const MANAGER_FILTER: Filter = { key: 'manager', label: 'Manager' }
export const DATE_FILTER: Filter = { key: 'date', label: 'Date' }
export const UPLOAD_DATE_FILTER: Filter = { key: 'date', label: 'Upload Date' }
export const CREATED_DATE_FILTER: Filter = { key: 'date', label: 'Created Date' }
export const CLOSED_DATE_FILTER: Filter = { key: 'closedDate', label: 'Closed Date' }
export const EXPIRY_DATE_FILTER: Filter = { key: 'expiryDate', label: 'Expiration' }
export const STAGE_FILTER: Filter = { key: 'stagename', label: 'Stage' }
export const FILETYPE_FILTER: Filter = { key: 'filetype', label: 'File type' }
export const GROUP_FILTER: Filter = { key: 'group', label: 'Group' }
export const ROLE_FILTER: Filter = { key: 'role', label: 'Role' }
export const STATUS_FILTER: Filter = { key: 'status', label: 'Status' }
export const CONTENT_TYPE_FILTER: Filter = { key: 'contentType', label: 'Content Type' }
export const ARCHIVE_STATUS_FILTER: Filter = { key: 'archiveStatus', label: 'Archive' }
export const TEMPLATE_FILTER: Filter = { key: 'isTemplate', label: 'Template' }
export const SHARING_PERMISSION_FILTER: Filter = { key: 'sharingPermission', label: 'Access' }
export const HUB_FILTER: Filter = { key: 'hub', label: 'Hub' }

const DEFAULT_FILTERS: Filters = {
  tag: [],
  opportunity: [],
  author: [],
  manager: [],
  stagename: [],
  group: [],
  filetype: [],
  fromDate: undefined,
  toDate: undefined,
  expiryFromDate: undefined,
  expiryToDate: undefined,
  closedFromDate: undefined,
  closedToDate: undefined,
  role: [],
  status: [],
  contentType: [],
  archiveStatus: [],
  isTemplate: [],
  sharingPermission: [],
}

@observer
export class FjFilter extends Component<RouteComponentProps<{}> & FjFilterProps> {
  static defaultProps: Partial<FjFilterProps> = {
    showSort: true,
    showFilter: true,
    showViewSwitcher: true,
    excludeSearchParams: [],
  }
  abortController: AbortController
  @observable selectedFilters: Filters = DEFAULT_FILTERS
  @observable selectedFilterParams: object = {}
  disposer: IReactionDisposer

  constructor(props: RouteComponentProps<{}> & FjFilterProps) {
    super(props)
    makeObservable(this)
  }

  @computed get customMetadataFieldFilterKeys() {
    const { overrideCustomMetadataFieldOptions } = this.props
    return sharedDataStore.customMetadataFieldNames
      .filter((key) => {
        if (!overrideCustomMetadataFieldOptions) return true
        // Display only the filters that have custom metadata field options; if there are no options, we hide the filter.
        if (Object.keys(overrideCustomMetadataFieldOptions[key] ?? {}).length > 0) return true
        return false
      })
      .map((key) => `custom_fields__${key}`)
  }

  @computed get customMetadataFieldFilters() {
    return this.customMetadataFieldFilterKeys.map((key) => ({
      key: key,
      label: titleCase(key.replace('custom_fields__', ''), '_'),
    }))
  }

  @computed get filters() {
    const isSfdcConnected = !!sharedDataStore.user.company.integratedSalesforceOrganizationUrl
    const shouldIncludeOppFilter = isSfdcConnected && !sharedDataStore.user.isFaasPublicOrPartner()
    const isPartOfManyGroups = sharedDataStore.user.isPartOfManyGroups()
    const isAdminUser = sharedDataStore.user.isFaasAdmin()
    const isPublicUser = sharedDataStore.user.isFaasPublic()

    const filters = {
      top: [
        TAG_FILTER,
        shouldIncludeOppFilter ? OPPORTUNITY_FILTER : null,
        !isPublicUser ? AUTHOR_FILTER : null,
        !isPublicUser ? DATE_FILTER : null,
      ].filter(Boolean),
      managerPicks: [],
      favourites: [],
      fullsearch: [
        HUB_FILTER,
        CONTENT_TYPE_FILTER,
        TAG_FILTER,
        !isPublicUser ? AUTHOR_FILTER : null,
        shouldIncludeOppFilter ? OPPORTUNITY_FILTER : null,
        shouldIncludeOppFilter ? STAGE_FILTER : null,
        !isPublicUser ? DATE_FILTER : null,
        isPartOfManyGroups ? GROUP_FILTER : null,
        ...this.customMetadataFieldFilters,
        isAdminUser ? ARCHIVE_STATUS_FILTER : null,
        isAdminUser ? EXPIRY_DATE_FILTER : null,
      ].filter(Boolean),
      aisearch: [
        CONTENT_TYPE_FILTER,
        TAG_FILTER,
        !isPublicUser ? AUTHOR_FILTER : null,
        shouldIncludeOppFilter ? OPPORTUNITY_FILTER : null,
        shouldIncludeOppFilter ? STAGE_FILTER : null,
        !isPublicUser ? DATE_FILTER : null,
        isPartOfManyGroups ? GROUP_FILTER : null,
        ...this.customMetadataFieldFilters,
        isAdminUser ? ARCHIVE_STATUS_FILTER : null,
        isAdminUser ? EXPIRY_DATE_FILTER : null,
      ].filter(Boolean),
      feedpost: [
        HUB_FILTER,
        TAG_FILTER,
        shouldIncludeOppFilter ? OPPORTUNITY_FILTER : null,
        shouldIncludeOppFilter ? STAGE_FILTER : null,
        isPartOfManyGroups ? GROUP_FILTER : null,
        !isPublicUser ? AUTHOR_FILTER : null,
        !isPublicUser ? DATE_FILTER : null,
      ].filter(Boolean),
      playlist: [
        HUB_FILTER,
        TAG_FILTER,
        shouldIncludeOppFilter ? OPPORTUNITY_FILTER : null,
        shouldIncludeOppFilter ? STAGE_FILTER : null,
        isPartOfManyGroups ? GROUP_FILTER : null,
        !isPublicUser ? AUTHOR_FILTER : null,
        !isPublicUser ? DATE_FILTER : null,
        ...this.customMetadataFieldFilters,
      ].filter(Boolean),
      prompt: [
        HUB_FILTER,
        TAG_FILTER,
        isPartOfManyGroups ? GROUP_FILTER : null,
        !isPublicUser ? AUTHOR_FILTER : null,
        !isPublicUser ? DATE_FILTER : null,
      ].filter(Boolean),
      course: [
        ...(this.props.overrideFilters?.course ?? [
          HUB_FILTER,
          TAG_FILTER,
          shouldIncludeOppFilter ? OPPORTUNITY_FILTER : null,
          shouldIncludeOppFilter ? STAGE_FILTER : null,
          isPartOfManyGroups ? GROUP_FILTER : null,
          !isPublicUser ? AUTHOR_FILTER : null,
          !isPublicUser ? DATE_FILTER : null,
        ]),
        ...this.customMetadataFieldFilters,
      ].filter(Boolean),
      learningpath: [
        ...(this.props.overrideFilters?.learningpath ?? [
          HUB_FILTER,
          TAG_FILTER,
          shouldIncludeOppFilter ? OPPORTUNITY_FILTER : null,
          shouldIncludeOppFilter ? STAGE_FILTER : null,
          isPartOfManyGroups ? GROUP_FILTER : null,
          !isPublicUser ? AUTHOR_FILTER : null,
          !isPublicUser ? DATE_FILTER : null,
        ]),
        ...this.customMetadataFieldFilters,
      ].filter(Boolean),
      opportunity: [
        OWNER_FILTER,
        STAGE_FILTER,
        !isPublicUser ? CREATED_DATE_FILTER : null,
        !isPublicUser ? CLOSED_DATE_FILTER : null,
      ],
      call: [
        HUB_FILTER,
        OWNER_FILTER,
        shouldIncludeOppFilter ? OPPORTUNITY_FILTER : null,
        shouldIncludeOppFilter ? STAGE_FILTER : null,
        !isPublicUser ? DATE_FILTER : null,
      ].filter(Boolean),
      asset: [
        HUB_FILTER,
        FILETYPE_FILTER,
        shouldIncludeOppFilter ? OPPORTUNITY_FILTER : null,
        shouldIncludeOppFilter ? STAGE_FILTER : null,
        TAG_FILTER,
        isPartOfManyGroups ? GROUP_FILTER : null,
        OWNER_FILTER,
        UPLOAD_DATE_FILTER,
        SHARING_PERMISSION_FILTER,
        !isPublicUser ? TEMPLATE_FILTER : null,
        ...this.customMetadataFieldFilters,
      ].filter(Boolean),
      hub: [],
      user: [ROLE_FILTER, STATUS_FILTER, GROUP_FILTER, MANAGER_FILTER],
      dealroom: [
        HUB_FILTER,
        shouldIncludeOppFilter ? OPPORTUNITY_FILTER : null,
        shouldIncludeOppFilter ? STAGE_FILTER : null,
        isPartOfManyGroups ? GROUP_FILTER : null,
        !isPublicUser ? AUTHOR_FILTER : null,
        !isPublicUser ? DATE_FILTER : null,
        !isPublicUser ? TEMPLATE_FILTER : null,
      ].filter(Boolean),
      template: [CONTENT_TYPE_FILTER],
    }

    if (sharedDataStore.user.isFaasAdmin()) {
      filters.feedpost.push(ARCHIVE_STATUS_FILTER, EXPIRY_DATE_FILTER)
      filters.asset.push(ARCHIVE_STATUS_FILTER, EXPIRY_DATE_FILTER)
      filters.playlist.push(ARCHIVE_STATUS_FILTER, EXPIRY_DATE_FILTER)
      filters.course.push(ARCHIVE_STATUS_FILTER, EXPIRY_DATE_FILTER)
      filters.learningpath.push(ARCHIVE_STATUS_FILTER, EXPIRY_DATE_FILTER)
      filters.prompt.push(ARCHIVE_STATUS_FILTER, EXPIRY_DATE_FILTER)
      filters.dealroom.push(ARCHIVE_STATUS_FILTER, EXPIRY_DATE_FILTER)
    }
    return filters
  }

  @computed get filtersLength() {
    return Object.keys(this.selectedFilters)
      .map((key) =>
        AUTOCOMPLETE_KEYS.includes(key as AutoCompleteFilterKey) ||
        SELECT_KEYS.includes(key as SelectFilterKey) ||
        key.includes('custom_fields')
          ? this.selectedFilters[key].length
          : Number(!!this.selectedFilters[key])
      )
      .reduce((a, b) => a + b)
  }

  @computed get contentTypeOptions() {
    const { overrideContentTypeOptions } = this.props
    if (overrideContentTypeOptions)
      return overrideContentTypeOptions.map((item) => ({
        label: getContentCategoryTitle(item).slice(0, -1),
        value: item,
        id: item,
        key: item,
      }))
    return contentTypeOptions
  }
  componentDidMount = async () => {
    this.initializeSelectedFiltersFromURL()
    this.disposer = autorun(() => {
      if (sharedDataStore.config.learningContentCustomFields) {
        this.initializeSelectedFiltersFromURL()
      }
    })
  }

  componentDidUpdate(prevProps: Readonly<RouteComponentProps<{}> & FjFilterProps>): void {
    const { category } = this.props
    if (prevProps.category !== category) {
      this.selectedFilters = DEFAULT_FILTERS
    }
    if (this.props.location.search !== prevProps.location.search) {
      this.selectedFilterParams = {}
      this.initializeSelectedFiltersFromURL()
    }
  }

  componentWillUnmount(): void {
    this.disposer?.()
  }

  getCustomMetadataFieldOptions = (key: string) => {
    key = key.replace('custom_fields__', '')
    const customFields =
      this.props.overrideCustomMetadataFieldOptions ?? sharedDataStore.config?.learningContentCustomFields
    return (customFields[key] ?? []).map((val) => ({
      key: val,
      id: val,
      value: val,
      label: val,
    }))
  }

  initializeSelectedFiltersFromURL = async () => {
    try {
      const {
        author_id,
        manager,
        opp_id,
        tag,
        stage_name,
        file_type,
        created_at_after,
        created_at_before,
        expiry_date_before,
        expiry_date_after,
        closed_date_before,
        closed_date_after,
        group,
        archive_status,
        is_internal,
        content_type,
        is_template,
        ...restParams
      } = getQueryParams(this.props.excludeSearchParams)
      this.abortController?.abort()
      this.abortController = new AbortController()
      this.selectedFilters = {
        author: author_id
          ? await (
              await Promise.all(
                author_id.split(',').map((id) => User.get(id, undefined, undefined, undefined, this.abortController))
              )
            ).map((user: User) => {
              return { ...user, label: user.fullName }
            })
          : [],
        manager: manager
          ? await (
              await Promise.all(
                manager.split(',').map((id) => User.get(id, undefined, undefined, undefined, this.abortController))
              )
            ).map((user: User) => {
              return { ...user, label: user.fullName }
            })
          : [],
        opportunity: opp_id
          ? await (
              await Promise.all(
                opp_id
                  .split(',')
                  .map((o) => SfOpportunity.get(o, undefined, undefined, undefined, this.abortController))
              )
            ).map((opp: SfOpportunity) => {
              return { ...opp, label: opp.title }
            })
          : [],
        tag: tag
          ? await (
              await Promise.all(
                tag.split(',').map((t) => FeedTag.get(t, undefined, undefined, undefined, this.abortController))
              )
            )?.map((feedTag: FeedTag) => {
              return { ...feedTag, label: feedTag.tag }
            })
          : [],
        stagename: stage_name
          ? stage_name
              .split(',')
              .map((s) => sharedDataStore.stageNameOptions.find((option) => option.value === s))
              .filter(Boolean)
          : [],
        group: group
          ? group
              .split(',')
              .map((s) => sharedDataStore.groupOptions.find((option) => option.value === s))
              .filter(Boolean)
          : [],
        filetype: file_type
          ? file_type
              .split(',')
              .map((f) => sharedDataStore.assetFileTypeOptions.find((option) => option.value === f))
              .filter(Boolean)
          : [],
        fromDate: created_at_after && moment(created_at_after).isValid() ? moment(created_at_after) : undefined,
        toDate: created_at_before && moment(created_at_before).isValid() ? moment(created_at_before) : undefined,
        expiryFromDate:
          expiry_date_after && moment(expiry_date_after).isValid() ? moment(expiry_date_after) : undefined,
        expiryToDate:
          expiry_date_before && moment(expiry_date_before).isValid() ? moment(expiry_date_before) : undefined,
        closedFromDate:
          closed_date_after && moment(closed_date_after).isValid() ? moment(closed_date_after) : undefined,
        closedToDate:
          closed_date_before && moment(closed_date_before).isValid() ? moment(closed_date_before) : undefined,
        // This needs to revisite later, because when url changes, it always reset with initialFitlers['role'].
        // It doesn't cause issue now because role filter only used in Team page and we don't update the url there.
        // Story ID - #186923061
        role: this.props.initialFilters?.['role'] ?? [],
        status: [],
        contentType: content_type
          ? content_type
              .split(',')
              .map((s) => this.contentTypeOptions.find((option) => option.value === s))
              .filter(Boolean)
          : [],
        archiveStatus: archive_status
          ? archive_status
              .split(',')
              .map((f) => archiveStatusOptions.find((option) => option.value === f))
              .filter(Boolean)
          : [],
        isTemplate: is_template
          ? is_template
              .split(',')
              .map((f) => templateFilterOptions.find((option) => option.value === f))
              .filter(Boolean)
          : [],
        sharingPermission: is_internal
          ? is_internal
              .split(',')
              .map((f) => sharingPermissionOptions.find((option) => option.value === f))
              .filter(Boolean)
          : [],
      }
      for (const key of Object.keys(restParams)) {
        this.selectedFilters[key] = restParams[key]
          .split(',')
          .map((value) => this.getCustomMetadataFieldOptions(key).find((option) => option.value === value))
          .filter(Boolean)
      }
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  onFilterChange = debounce((param: object) => {
    for (const [key, value] of Object.entries(param)) {
      this.selectedFilterParams[key] = value
    }
    this.props.onFiltersChange(clearFalseyValues({ ...this.getFilterQueryParams(), ...param }))
  }, 500)

  addFilterOption = (key: FilterOptionKey, value: any) => {
    const { onFiltersChange } = this.props
    if (
      AUTOCOMPLETE_KEYS.includes(key as AutoCompleteFilterKey) ||
      SELECT_KEYS.includes(key as SelectFilterKey) ||
      key.includes('custom_fields')
    ) {
      const filterKey = key as AutoCompleteFilterKey | SelectFilterKey
      // Reset selectedFilters as it doesn't have default value for Custom Fields.
      if (!this.selectedFilters[filterKey]) this.selectedFilters[filterKey] = []

      if (this.selectedFilters[filterKey].findIndex((f) => f.id === value.id) !== -1) return
      this.selectedFilters[filterKey].push(value)
    } else if (DATE_KEYS.includes(key as DateFilterKey)) {
      const dateFilterKey = key
      this.selectedFilters[dateFilterKey] = value
    }
    onFiltersChange(this.getFilterQueryParams())
  }

  removeFilterOption = (key: FilterOptionKey, value?: any) => {
    const { onFiltersChange } = this.props
    if (
      AUTOCOMPLETE_KEYS.includes(key as AutoCompleteFilterKey) ||
      SELECT_KEYS.includes(key as SelectFilterKey) ||
      key.includes('custom_fields')
    ) {
      const filterKey = key as AutoCompleteFilterKey | SelectFilterKey
      this.selectedFilters[filterKey] = this.selectedFilters[filterKey].filter((f) => f.id !== value.id)
    } else {
      const dateFilterKey = key
      this.selectedFilters[dateFilterKey] = undefined
    }
    onFiltersChange(this.getFilterQueryParams())
  }

  clearFilter = (key: FilterKey) => {
    const { onFiltersChange } = this.props
    if (
      AUTOCOMPLETE_KEYS.includes(key as AutoCompleteFilterKey) ||
      SELECT_KEYS.includes(key as SelectFilterKey) ||
      key.includes('custom_fields')
    ) {
      const filterKey = key as AutoCompleteFilterKey | SelectFilterKey
      this.selectedFilters[filterKey] = []
    } else {
      if (key === 'date') {
        this.selectedFilters['fromDate'] = undefined
        this.selectedFilters['toDate'] = undefined
      } else if (key === 'expiryDate') {
        this.selectedFilters['expiryFromDate'] = undefined
        this.selectedFilters['expiryToDate'] = undefined
      } else if (key === 'closedDate') {
        this.selectedFilters['closedFromDate'] = undefined
        this.selectedFilters['closedToDate'] = undefined
      }
    }
    onFiltersChange(this.getFilterQueryParams())
  }

  getFilterQueryParams = () => {
    let filterQueryParams = {
      author_id: this.selectedFilters['author'].length
        ? this.selectedFilters['author'].map((e: User) => e.id).join(',')
        : null,
      manager: this.selectedFilters['manager'].length
        ? this.selectedFilters['manager'].map((e: User) => e.id).join(',')
        : null,
      opp_id: this.selectedFilters['opportunity'].length
        ? this.selectedFilters['opportunity'].map((e: SfOpportunity) => e.id).join(',')
        : null,
      tag: this.selectedFilters['tag'].length ? this.selectedFilters['tag'].map((e: FeedTag) => e.tag).join(',') : null,
      stage_name: this.selectedFilters['stagename'].length
        ? this.selectedFilters['stagename'].map((e: { value: string }) => e.value).join(',')
        : null,
      group: this.selectedFilters['group'].length
        ? this.selectedFilters['group'].map((e: { value: string }) => e.value).join(',')
        : null,
      file_type: this.selectedFilters['filetype'].length
        ? this.selectedFilters['filetype'].map((e: { value: string }) => e.value).join(',')
        : null,
      created_at_after: this.selectedFilters['fromDate'] ? this.selectedFilters['fromDate'].toISOString() : null,
      created_at_before: this.selectedFilters['toDate'] ? this.selectedFilters['toDate'].toISOString() : null,
      expiry_date_before: this.selectedFilters['expiryToDate']
        ? this.selectedFilters['expiryToDate'].toISOString()
        : null,
      expiry_date_after: this.selectedFilters['expiryFromDate']
        ? this.selectedFilters['expiryFromDate'].toISOString()
        : null,
      closed_date_before: this.selectedFilters['closedToDate']
        ? this.selectedFilters['closedToDate'].toISOString()
        : null,
      closed_date_after: this.selectedFilters['closedFromDate']
        ? this.selectedFilters['closedFromDate'].toISOString()
        : null,
      access_role: this.selectedFilters['role'].length
        ? this.selectedFilters['role'].map((e: { value: string }) => e.value).join(',')
        : null,
      status: this.selectedFilters['status'].length
        ? this.selectedFilters['status'].map((e: { value: string }) => e.value).join(',')
        : null,
      content_type: this.selectedFilters['contentType'].length
        ? this.selectedFilters['contentType'].map((e: { value: string }) => e.value).join(',')
        : null,
      archive_status: this.selectedFilters['archiveStatus'].length
        ? this.selectedFilters['archiveStatus'].map((e: { value: string }) => e.value).join(',')
        : null,
      is_template: this.selectedFilters['isTemplate'].length
        ? this.selectedFilters['isTemplate'].map((e: { value: string }) => e.value).join(',')
        : null,
      is_internal: this.selectedFilters['sharingPermission'].length
        ? this.selectedFilters['sharingPermission'].map((e: { value: string }) => e.value).join(',')
        : null,
      hub_id: getQueryParam('hub_id') || this.selectedFilterParams['hub_id'],
    }
    this.customMetadataFieldFilterKeys.map((key) => {
      if (this.selectedFilters[key]?.length) {
        filterQueryParams[key] = this.selectedFilters[key].map(({ value }) => value).join(',')
      }
      return null
    })

    for (const key in filterQueryParams) {
      if (filterQueryParams[key] === null) {
        delete filterQueryParams[key]
      }
    }

    return filterQueryParams
  }

  sortOptions = () =>
    new Map([
      ['recent', 'Most Recent'],
      ['popular', 'Most Popular'],
      ['alphabetical', 'A-Z'],
    ])

  render() {
    const { category, handleSetSortBy, sortBy, showSort, showViewSwitcher } = this.props
    const categoryFilters = this.filters[category].filter((item) => !this.props.excludeFilters?.includes(item.key))
    const showFilter =
      (categoryFilters.length > 0 || !notSortSupportedCategories.includes(category)) && this.props.showFilter

    return (
      <ContainerDiv
        display="flex"
        width="100%"
        justifyContent="space-between"
        flexWrap={sharedAppStateStore.isMobile ? 'wrap' : undefined}
        gap={8}
      >
        {showFilter ? (
          <ContainerDiv
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            gap={8}
            justifyContent="flex-start"
            overflow="auto"
          >
            {categoryFilters.map((filter) => {
              return filter.key === 'hub' ? (
                <HubFilter onChange={this.onFilterChange} />
              ) : (
                <FilterDropdown
                  filter={filter}
                  contentTypeOptions={this.contentTypeOptions}
                  selectedFilters={this.selectedFilters}
                  addFilterOption={this.addFilterOption}
                  removeFilterOption={this.removeFilterOption}
                  clearFilter={this.clearFilter}
                  getCustomMetadataFieldOptions={this.getCustomMetadataFieldOptions}
                  key={`filter-${category}-${filter.key}`}
                />
              )
            })}
          </ContainerDiv>
        ) : (
          <div />
        )}
        <ContainerDiv display="flex" justifyContent="end" alignItems="start" gap={2} marginLeft="auto">
          <ContainerDiv display="flex" alignItems="center" gap={8}>
            {showSort ? (
              <ContainerDiv marginLeft="auto">
                {!notSortSupportedCategories.includes(category) && (
                  <FilterSelect
                    value={sortBy}
                    onChange={handleSetSortBy}
                    style={{ width: 'max-content' }}
                    placeholder={<FilterIcon color={Colors.cornflowerBlue} size={16} />}
                  >
                    {Array.from(this.sortOptions()).map(([key, value]) => (
                      <Select.Option key={key} value={key}>
                        {value}
                      </Select.Option>
                    ))}
                  </FilterSelect>
                )}
              </ContainerDiv>
            ) : null}
            {showViewSwitcher ? (
              <div>
                <ViewSwitcher {...this.props.viewSwitcherProps} />
              </div>
            ) : null}
          </ContainerDiv>
        </ContainerDiv>
      </ContainerDiv>
    )
  }
}
